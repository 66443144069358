export default function getMediaUrl(file, format) {
  if (typeof file === "string") return file;

  if (typeof file !== "object") return "";

  if (file?.formats?.[format]) {
    return file.formats[format]?.url;
  }

  return file?.url;
}
