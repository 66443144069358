"use client";

import { useEffect, useState } from "react";
import Button from "@/components/Button";
import Menu from "@mui/material/Menu";
import TextField from "@mui/material/TextField";
import formatPrice from "@/utils/formatPrice";

const PriceRange = (props) => {
  const {
    onChange,
    variant,
    className,
    defaultValue = { min: null, max: null },
    ...params
  } = props;
  const [valid, setValid] = useState(true);
  const [min, setMin] = useState(defaultValue.min);
  const [max, setMax] = useState(defaultValue.max);
  const [anchorEl, setAnchorEl] = useState(null);
  const [numericEl, setNumericEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!open) return;
    import("@/components/fields/Numeric").then((mod) =>
      setNumericEl(mod.default)
    );
  }, [open]);

  const setMinPrice = (min) => {
    setValid(!(min && max && max < min));
    min = min && !isNaN(min) && min > 0 ? min : null;
    setMin(min);
    onChange({ price_min: min });
  };

  const setMaxPrice = (max) => {
    setValid(!(min && max && max < min));
    max = max && !isNaN(max) && max > 0 ? max : null;
    setMax(max);
    onChange({ price_max: max });
  };

  const Fields = (
    <div className={className}>
      <div className="flex gap-2">
        <TextField
          label="Min Price"
          className="flex-1"
          value={min}
          onChange={(e) => {
            setMinPrice(parseInt(e.target.value));
          }}
          name="min"
          id="price-min"
          InputProps={{
            inputComponent: numericEl,
          }}
        />

        <TextField
          label="Max Price"
          className="flex-1"
          value={max}
          onChange={(e) => {
            setMaxPrice(parseInt(e.target.value));
          }}
          name="max"
          id="price-max"
          InputProps={{
            inputComponent: numericEl,
          }}
        />
      </div>
      {!valid && (
        <div className="text-red-500 text-xs mt-2">
          Min should be less than Max Price. Range will be Flipped
        </div>
      )}
    </div>
  );

  return (
    <div className={`flex h-100 ${className}`}>
      <Button
        id="price-button"
        aria-controls={open ? "price-menu" : undefined}
        aria-haspopup="false"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="btn-menu"
        type="button"
      >
        {!min && !max && "Price (AED)"}
        {min && !max && `> ${formatPrice(min, 1)}`}
        {!min && max && `< ${formatPrice(max, 1)}`}
        {min && max && `${formatPrice(min, 1)} - ${formatPrice(max, 1)}`}
        {(min || max) && <label>Price (AED)</label>}
      </Button>

      <Menu
        id="price-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
      >
        <div className="p-4 md:max-w-92">
          <div className="text-xs text-muted mb-6">Select Price Range</div>
          {Fields}
        </div>
      </Menu>
    </div>
  );
};

export default PriceRange;
