import "@/assets/styles/js/swiper";

import { Swiper as DefaultSwiper, SwiperSlide } from "swiper/react";
import { A11y, Pagination } from "swiper/modules";
import { Children } from "react";
import cn from "@/utils/cn";

export default function BaseSwiper({ children, id, ...props }) {
  return (
    <div className={cn("relative w-100 isolate", props.className)}>
      <DefaultSwiper {...props} modules={[Pagination, A11y]} id={id}>
        {Children.map(children, (child, index) => (
          <SwiperSlide key={index} className={`!h-auto ${id}-slide`}>
            {child}
          </SwiperSlide>
        ))}
      </DefaultSwiper>
    </div>
  );
}
