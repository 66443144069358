"use client";

import Swiper from "@/components/Swiper/Swiper";

const ImagesSwiper = ({ children }) => {
  return (
    <div className="image-swiper relative h-full">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        pagination={{
          clickable: false,
        }}
        breakpoints={{}}
        className="h-full"
      >
        {children}
      </Swiper>
    </div>
  );
};
export default ImagesSwiper;
