"use client";

// eslint-disable-next-line no-restricted-imports
import Link from "next/link";
import { usePathname } from "next/navigation";
import parseUrl from "url-parse";

/**
 * Sets the main loading screen attribute on the document element.
 *
 * @param {import("url-parse").Url} url - The URL to navigate to.
 * @return {void}
 */
const showMainLoadingScreen = (url) => {
  if (url.href === document.location.href) return hideMainLoadingScreen();
  document.documentElement.setAttribute("main-loading-screen", "");
};

export const hideMainLoadingScreen = () => {
  document.documentElement.removeAttribute("main-loading-screen");
};

export default function NavigationLink({
  onClick,
  showActiveStyle = false,
  href = "#",
  ...props
}) {
  const pathname = usePathname();
  const target = props?.target ?? (href?.startsWith("/") ? "_self" : "_blank");

  const rel = target === "_blank" ? "noopener noreferrer" : undefined;

  const url = new parseUrl(href);

  const isActiveLink =
    pathname === url.pathname && showActiveStyle && target !== "_blank";

  const activeStyles = {
    fontWeight: "bold",
  };

  return (
    <Link
      {...props}
      href={href}
      target={target}
      rel={rel}
      style={{ ...props?.style, ...(isActiveLink ? activeStyles : {}) }}
      prefetch={false}
      onClick={(event) => {
        if (onClick) onClick(event);
        if (
          target === "_blank" ||
          event.ctrlKey ||
          event.metaKey ||
          event.shiftKey ||
          event?.button === 1
        )
          return;

        showMainLoadingScreen(url);
      }}
    />
  );
}
