"use client";

import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@/hooks/useMediaQuery";
import dynamic from "next/dynamic";
import { useState } from "react";
import useIsInteractive from "@/hooks/useIsInteractive";

const Loading = () => (
  <Skeleton variant="rectangular" className="w-1/2 rounded" height={30} />
);

const BaseContactInfo = dynamic(() => import("./ContactInfo"), {
  ssr: false,
  loading: Loading,
});

export default function ContactInfo({
  hideContactInfo = false,
  queryInput = "",
  ...props
}) {
  // const isMobile = useMediaQuery(queryInput);
  //
  // const hide =
  //   typeof hideContactInfo === "boolean"
  //     ? hideContactInfo
  //     : hideContactInfo?.mobile === true && isMobile;

  return <BaseContactInfo {...props} />;
}
