import React from "react";
import cn from "@/utils/cn";

const Button = React.forwardRef((props, ref) => {
  const { children, className, ...rest } = props;
  return (
    <button
      className={cn(
        "btn select-none disabled:opacity-60 disabled:pointer-events-none",
        className,
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </button>
  );
});

Button.displayName = "Button";

export default Button;
