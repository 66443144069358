"use client";

import React, { Children, useId } from "react";
import BaseSwiper from "@/components/Swiper/BaseSwiper";

const defaultBreakpoints = {
  768: {
    slidesPerView: 2,
    spaceBetween: 16,
  },
  1024: {
    slidesPerView: 3,
  },
};
const defaultSpaceBetween = 20;
const defaultSlidesPerView = 1;
const defaultAutoHeight = false;
const defaultPagination = { clickable: true };

const Swiper = ({
  children,
  breakpoints = defaultBreakpoints,
  spaceBetween = defaultSpaceBetween,
  slidesPerView = defaultSlidesPerView,
  autoHeight = defaultAutoHeight,
  className = "",
  pagination = defaultPagination,
  modules = [],
  ...rest
}) => {
  const id = "swiper-" + useId().replace(/:/g, "");
  const _breakpoints = { 0: { slidesPerView: 1 }, ...breakpoints };

  const _className = Object.entries(_breakpoints)
    .map(([key, value]) => {
      const { slidesPerView = 1, spaceBetween: gap = spaceBetween } = value;
      return `
      @media (min-width: ${key}px) {
        .${id}-slide {
          width: calc((100% - ${
            (slidesPerView - 1) * gap
          }px) / ${slidesPerView});
          margin-right: ${gap}px;
        }            
      }`;
    })
    .join(" ")
    .replace(/\s+/g, " ");

  return (
    <>
      <style>{_className}</style>
      <BaseSwiper
        breakpoints={_breakpoints}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        autoHeight={autoHeight}
        className={className}
        pagination={pagination}
        id={id}
        {...rest}
      >
        {children}
      </BaseSwiper>
    </>
  );
};

export default Swiper;
