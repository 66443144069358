import { useEffect, useRef } from "react";

let cachedValue = null;

export default function useIsInteractive(cb) {
  const loaded = useRef(false);

  useEffect(() => {

    if (cachedValue) {
      cb();
      loaded.current = true;
    }

    if (loaded.current) return;

    const playVideo = () => {
      if (loaded.current) return;
      loaded.current = true;

      cachedValue = true;

      if (cb) cb();
    };

    window.addEventListener("mousemove", playVideo);
    window.addEventListener("touchstart", playVideo);
    window.addEventListener("focus", playVideo);
    window.addEventListener("scroll", playVideo);
    window.addEventListener("resize", playVideo);
    window.addEventListener("pointerdown", playVideo);

    return () => {
      window.removeEventListener("mousemove", playVideo);
      window.removeEventListener("touchstart", playVideo);
      window.removeEventListener("focus", playVideo);
      window.removeEventListener("scroll", playVideo);
      window.removeEventListener("resize", playVideo);
      window.removeEventListener("pointerdown", playVideo);
    };
  }, []);
}
