"use client";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AppRouterCacheProvider } from "@mui/material-nextjs/v13-appRouter";

const themeOptions = {
  palette: {
    primary: {
      main: "#CB3F60",
    },
    secondary: {
      main: "#CB3F60",
    },
    white: {
      main: "#ffffff",
    },
    whatsapp: {
      main: "#04A884",
    },
    grey: {
      main: "#DDDDDD",
    },
  },
  typography: {
    fontFamily: "proxima-nova, sans-serif",
    button: {
      textTransform: "none",
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

const ThemeProviderHOC = ({ children }) => {
  return (
    <AppRouterCacheProvider>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AppRouterCacheProvider>
  );
};

export default ThemeProviderHOC;
